import React from "react";
import "./HeroSection.css";
import heroBg from "../../assets/images/hero-image.webp";

const HeroSection = () => {
  return (
    <div className="hero-section text-center py-5">
      <div className="container">
        <h1 className="display-1 fw-bold text-light">
          <span>CRAFTING</span> <br />
          <span>BRANDS</span>{" "}
          <span className="star-icon mx-2">&#x2736;</span> <span>WITH</span>
          <br />
          <span className="d-block">PURPOSE</span>
        </h1>
        <div
          className="image-placeholder my-4 mx-auto"
          style={{ backgroundImage: `url(${heroBg})` }}
        ></div>
        <p className="lead text-light">
          Ivory is a premier branding agency dedicated to crafting identities
          that empower businesses to <br />
          stand out with creative expertise.
        </p>
        <div className="d-flex justify-content-center align-items-center mt-3">
          <span className="scroll-icon me-2">&#9675;</span>
          <span className="text-light small">SCROLL TO EXPLORE</span>
        </div>
        <button className="btn btn-light rounded-pill mt-4 fw-bold px-4 py-2">
          BUY TEMPLATE <span className="ms-2">&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
